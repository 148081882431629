.edit-reviewer,
.edit-employee {
  button {
    padding: 5px 40px !important;
    &.btn-cancel {
      border: 1px solid #00cdc2;
      background-color: white !important;
      color: #00cdc2;
    }
  }
  .modal-footer {
    border-top: none !important;
  }
}

.edit-reviewer {
  width: 580px !important;
}
