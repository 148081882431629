// for date picker

.keclick-date-picker-container {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 40px;
  border-radius: 8px;
  position: relative;

  &.apply-shift-template {
    width: auto !important;

    input {
      box-shadow: 0 0 0 1px rgba(102, 51, 153, 0.2) !important;
    }
  }

  .date-input {
    width: 100%;
    height: 100%;
    // z-index: 1;
    position: relative;

    .react-datepicker-wrapper {
      width: 100% !important;
    }

    input {
      width: 100%;
      height: 40px;
      border: none;
      background: transparent !important;
      z-index: 1;
      position: relative;
      padding: 0 12px;
    }

    input:disabled {
      background: #b3b3b3 !important;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }
  }

  .icon {
    position: absolute;
    right: 12px;
    z-index: 0;

    img {
      width: 24px;
    }
  }

  .rdtPicker {
    border: 1px solid #5856d6;
    border-radius: 4px;
    padding: 0;
  }

  .rdtDays {
    thead > tr {
      border-bottom: 1px solid #5856d6;

      .dow {
        color: #808080;
      }
    }

    thead > tr:first-child {
      position: relative;
      height: 52px;
      border-bottom: 2px solid #5856d6;
      color: #808080;

      .rdtPrev {
        position: absolute;
        right: 30px;
        border: none;
        padding-top: 7px;

        &:hover {
          background: transparent;
        }
      }

      .rdtSwitch {
        position: absolute;
        text-align: left;
        left: 10px;
        border: none;
        padding-top: 15px;
        width: 200px;

        &:hover {
          background: transparent;
        }
      }

      .rdtNext {
        position: absolute;
        right: 10px;
        border: none;
        padding-top: 7px;

        &:hover {
          background: transparent;
        }
      }
    }

    .rdtDay {
      &:hover {
        background: transparent;
        color: #5856d6;
        font-weight: bold;
      }
    }

    .rdtToday {
      color: #1a1a1a;
      position: relative;

      &:hover {
        background: transparent;
      }

      &:before {
        border-bottom: none;
      }

      &:after {
        content: '';
        width: 30px;
        height: 30px;
        border: 1px solid #1a1a1a;
        display: block;
        position: absolute;
        border-radius: 100%;
        top: -1px;
        left: 3px;
      }
    }

    .rdtActive {
      position: relative;
      background: transparent;
      color: #fff !important;

      &:hover {
        color: #fff;
        font-weight: 500;
      }

      &:after {
        content: '';
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        background: #5856d6;
        border-radius: 100%;
        top: -1px;
        left: 3px;
        z-index: -1;
      }
    }
  }

  .rdtMonths,
  .rdtYears {
    thead > tr {
      border-bottom: 1px solid #5856d6;
    }

    thead > tr:first-child {
      position: relative;
      height: 52px;
      border-bottom: 2px solid #5856d6;
      color: #808080;

      .rdtPrev {
        position: absolute;
        right: 30px;
        border: none;
        padding-top: 7px;

        &:hover {
          background: transparent;
        }
      }

      .rdtSwitch {
        position: absolute;
        text-align: left;
        left: 10px;
        border: none;
        padding-top: 15px;
        width: 200px;

        &:hover {
          background: transparent;
        }
      }

      .rdtNext {
        position: absolute;
        right: 10px;
        border: none;
        padding-top: 7px;

        &:hover {
          background: transparent;
        }
      }
    }

    .rdtActive {
      position: relative;
      background: transparent !important;

      &:hover {
        color: #fff;
        font-weight: 500;
      }

      &:after {
        content: '';
        width: 60px;
        height: 30px;
        display: block;
        position: absolute;
        background: #5856d6;
        border-radius: 30px;
        top: 10px;
        left: 1px;
        z-index: -1;
      }
    }
  }
}

.ke-time-picker-container {
  position: relative;
  width: 100%;
  border-radius: 4px;
  background: #e5e5e5;

  input {
    padding-right: 30px;
    background: transparent !important;
    z-index: 1;
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 8px;
    z-index: 0;
  }
}

.timepicker-ui-dot,
.timepicker-ui-clock-hand,
.timepicker-ui-circle-hand {
  background-color: #5856d6 !important;
}

.timepicker-ui-hour.active {
  color: #5856d6 !important;
  background-color: #ffcccc !important;
}

.timepicker-ui-pm.active {
  color: #5856d6 !important;
  background-color: #ffcccc !important;
}

.timepicker-ui-am.active {
  color: #5856d6 !important;
  background-color: #ffcccc !important;
}

.timepicker-ui-cancel-btn,
.timepicker-ui-ok-btn {
  color: #5856d6 !important;
  font-weight: 600;
}

.timepicker-ui-am {
  border: 1px solid #d6d6d6 !important;
}

.timepicker-ui-tips-wrapper {
  background: #f2f2f2;
  border-radius: 100%;
}
