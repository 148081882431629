.room-booking-wrap {
  .buttons-navigation {
    gap: 40px;
    margin-bottom: 40px;

    .btn-navigation {
      min-height: 80px;
      width: 180px;
      padding: 20px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      color: $asure-neutral-70;
      cursor: pointer;

      &.active {
        background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
        color: $asure-secondary-100;
      }
    }
  }
}

.room-booking-status {
  &.booked {
    color: #ffb600;
  }

  &.session-in-progress {
    color: #3cc13b;
  }
}

.bg-img-center {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.fw-bold {
  font-weight: bold;
}

.fs-20 {
  font-size: 20px;
}

.fs-14 {
  font-size: 14px;
}

.image-tag {
  padding: 0;

  label {
    padding: 5px;
    background: #63cd6280;
    border: 1px solid #63cd62;
    margin-right: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
