.interview-wrap {
  .react-bootstrap-table {
    tbody {
      tr {
        td {
          .dropdown-item:hover {
            background: var(
              --secondary-20,
              linear-gradient(0deg, rgba(0, 205, 194, 0.2) 0%, rgba(0, 205, 194, 0.2) 100%),
              #fff
            ) !important;
          }
          .dropdown-item:last-child {
            color: var(--error-80, #e83d33);
          }
        }
      }
    }
  }
  // .role-card {
  //   .card-body {
  //     padding: 0 !important;
  //     .card-head {
  //       margin: 0;
  //       padding: 20px 40px 0 40px;
  //     }
  //     .react-bootstrap-table {
  //       overflow-x: auto;
  //       thead {
  //         tr {
  //           th {
  //             border-bottom: 0;
  //             background-color: #f2f2f2;
  //             height: 56px;
  //             padding-left: 40px;
  //             padding-bottom: 20px;
  //           }
  //         }
  //       }
  //       tbody {
  //         tr {
  //           td {
  //             padding-left: 40px;
  //             .dropdown-item:hover {
  //               background: var(
  //                 --secondary-20,
  //                 linear-gradient(0deg, rgba(0, 205, 194, 0.2) 0%, rgba(0, 205, 194, 0.2) 100%),
  //                 #fff
  //               );
  //             }
  //           }
  //         }
  //       }
  //     }
  //     .react-bootstrap-table-pagination {
  //       margin: 0;
  //       padding: 10px 40px 20px 40px;
  //       .page-item.active .page-link {
  //         border-radius: 4px;
  //         color: #00cdc2;
  //         border: 1px solid var(--secondary-20, #fff);
  //         background: var(
  //           --secondary-10,
  //           linear-gradient(0deg, rgba(0, 205, 194, 0.1) 0%, rgba(0, 205, 194, 0.1) 100%),
  //           #fff
  //         );
  //         &:focus {
  //           box-shadow: none;
  //         }
  //       }
  //       .page-item {
  //         .page-link {
  //           border-radius: 4px;
  //           border: 1px solid var(--neutral-10, #e5e5e5);
  //           background: var(--neutral-0, #fff);
  //         }
  //       }
  //     }
  //   }
  // }
  .buttons-navigation {
    gap: 40px;
    margin-bottom: 40px;
    .btn-navigation {
      min-height: 80px;
      width: 200px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      color: $asure-neutral-70;
      cursor: pointer;
      &.active {
        background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
        color: $asure-secondary-100;
      }
    }
  }
  .role-status {
    &.open {
      color: $asure-success-100;
    }
    &.closed {
      color: $asure-error-100;
    }
  }
  .process-default {
    background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 10px;
    color: $asure-secondary-100;
    font-weight: 600;
  }
  .default-label {
    background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
    color: #00cdc2;
    border: 1px solid #00cdc2;
    padding: 4px 6px;
    border-radius: 4px;
    font-weight: 600;
  }
  // .react-bootstrap-table {
  //   overflow-x: auto !important;
  // }
  .react-bootstrap-table table {
    table-layout: auto;
  }
}

@media (max-width: 767px) {
  .interview-wrap {
    .buttons-navigation {
      flex-direction: column;
      .btn-navigation {
        width: 100%;
        min-height: auto;
      }
    }
  }
}

@media (min-width: 920px) {
  .large-dialog {
    .modal-dialog {
      max-width: 920px !important;
    }
  }
}
