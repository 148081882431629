/* Tagging Basic Style */
.tagging {
  border: 1px solid $gray-300;
  font-size: 1em;
  height: auto;
  padding: 10px 10px 15px;
  border-radius: 4px;
}

.tagging.editable {
  cursor: text;
}

.tag {
  background: none repeat scroll 0 0 $primary;
  border-radius: 2px;
  color: white;
  cursor: default;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  padding: 4px 20px 4px 0;
  margin: 5px 10px 0 0;
}

.tag span {
  background: none repeat scroll 0 0 darken($primary, 5);
  border-radius: 2px 0 0 2px;
  margin-right: 5px;
  padding: 5px 10px 5px;
}

.tag .tag-i {
  color: white;
  cursor: pointer;
  font-size: 1.3em;
  height: 0;
  line-height: 0.1em;
  position: absolute;
  right: 5px;
  top: 0.7em;
  text-align: center;
  width: 10px;
}

.tag .tag-i:hover {
  color: black;
  text-decoration: underline;
}

.type-zone {
  border: 0 none;
  height: auto;
  width: auto;
  min-width: 20px;
  display: inline-block;
}

.type-zone:focus {
  outline: none;
}
