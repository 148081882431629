.filter-bar-wrapper {
  gap: 10px;
  margin-bottom: 20px;
  .form-group {
    align-items: center;
    margin-bottom: 0;
  }
  input {
    width: 270px;
  }
  select.form-control {
    background: #fff;
    border: 1px solid #00cdc2;
    border-radius: 20px;
    min-width: 100px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .filter-bar-wrapper {
    flex-direction: column !important;
    margin-bottom: 10px;
    align-items: flex-start !important;
    > div {
      width: 100%;
    }
    input {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .date-range-picker-wrapper {
    position: relative;
    z-index: 1 !important;
  }
}
