.ul-accordion__link:hover {
  list-style: none;
  text-decoration: none !important;
}
button.ul-accordion__link {
  text-decoration: none !important;
}
.ul-accordion__font {
  font-size: 16px;
}
