.button-upload-file {
  label {
    background-color: $asure-secondary-10;
    color: #ffffff !important;
    padding: 8px 20px;
    font-family: sans-serif;
    border-radius: 100px;
    cursor: pointer;
    margin-top: 1rem;
  }
}
