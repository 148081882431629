@each $name, $value in $theme-colors {
  .border-bottom-#{$name} {
    border-bottom: 1px solid $value;
  }
}

@each $name, $value in $theme-colors {
  .border-bottom-dotted-#{$name} {
    border-bottom: 1px dotted $value;
  }
}
