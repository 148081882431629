.deskbooking-time-picker-style {
  background: #e5e5e5;
  width: 200px;
  border-radius: 5px;
  height: 40px;
  padding: 4px 15px;
  .ant-picker-input {
    width: 38px;
  }
  .ant-picker-suffix {
    margin-left: 30px;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      color: #000000
    }
  }
}
.time-slots {
  .checkbox .checkmark,
  .radio .checkmark {
    width: 24px;
    height: 24px;
    &::after {
      width: 8px;
      height: 9px;
      top: -2px
    }
  }
  select,
  input {
    width: 200px;
  }
}
:where(.css-dev-only-do-not-override-pr0fja).ant-tag.ant-tag-has-color,
:where(.css-dev-only-do-not-override-pr0fja).ant-tag{
  background-color: #00cdc2;
  height: 35px;
  border-radius: 15px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  font-size: 0.813rem;
  margin-bottom: 2px;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-select{
  border: initial;
  outline: initial !important;
  min-height: 40px;
  border-radius: 0.25rem;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-select-multiple .ant-select-selector {
  min-height: 40px;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-tag .ant-tag-close-icon {
  color: #ffffff
}