.shift-planning-wrap {
  .buttons-navigation {
    gap: 40px;
    margin-bottom: 40px;
    .btn-navigation {
      min-height: 80px;
      width: 240px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      color: $asure-neutral-70;
      cursor: pointer;
      &.active {
        background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
        color: $asure-secondary-100;
      }
    }
  }

  .react-bootstrap-table {
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 8px;
      visibility: hidden;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(221, 217, 217);
      outline: none;
      border-radius: 15px;
      visibility: hidden;
    }
    th.active {
      .text-center {
        background-color: rgba(0, 205, 194, 0.5);
        border-radius: 20px;
        padding: 2px;
      }
    }
  }

  .react-bootstrap-table table {
    table-layout: auto;
  }

  .shift-pattern-container {
    .shift-pattern-item {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
    }
  }
}

.shift-board-employee {
  .fc-daygrid-day-top {
    text-align: left !important;
  }
  .fc-event-main {
    .wrap-event {
      border-radius: 4px;
    }
    svg {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }
  .fc-day-today {
    background: none !important;
    background-color: none !important;
    .fc-daygrid-day-frame {
      background-color: rgba(0, 205, 194, 0.5);
    }
  }
  .fc-toolbar-chunk:first-child::before {
    content: '';
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
  }
}
