$font-family-heading: 'Open Sans', sans-serif;
$font-family-base: 'Open Sans', sans-serif;
$font-size-base: 0.813rem;
$font-family-secondary: 'Quicksand', sans-serif;

// // LAYOUT VARS
// $topbar-height: 80px;
// $topbar-height-mobile: 70px;

// $sidebar-left-width: 120px;
// $sidebar-left-width-mobile: 90px;
// $sidebar-left-secondary-width: 220px;
// $sidebar-left-secondary-width-mobile: 190px;
// $side-clearance: 20px;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$instagram: rgb(193, 53, 132);
$linkedin: rgb(0, 119, 181);
$dribble: #ea4c89;
$youtube: #c4302b;

// CUSTOM
$asure-primary-20: #deddf7;
$asure-primary-30: #cdccf3;
$asure-primary-100: #5856d6;
$asure-neutral-5: #f2f2f2;
$asure-neutral-10: #e5e5e5;
$asure-neutral-30: #b3b3b3;
$asure-neutral-40: #999999;
$asure-neutral-60: #666666;
$asure-neutral-70: #4d4d4d;
$asure-neutral-50: #808080;
$asure-neutral-80: #333333;
$asure-neutral-100: #000000;
$asure-secondary-10: #00cdc2;
$asure-secondary-60: #ebfbfa;
$asure-secondary-100: #00cdc2;
$asure-error-100: #e20c00;
$asure-success-100: #3cc13b;
$asure-tertiary-100: #ffb600;

// New Design
$primary-100: #02b9af;
$neutral-90: #1a1a1a;
$neutral-70: #4d4d4d;
