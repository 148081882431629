#calendar {
  float: right;
  width: 100%;
}

#external-events h4 {
  font-size: 16px;
  margin-top: 0;
  padding-top: 1em;
}

#external-events .fc-event {
  margin: 2px 0;
  cursor: move;
}

.create_event_wrap p {
  margin: 1.5em 0;
  font-size: 11px;
  color: #666;
}

.create_event_wrap p input {
  margin: 0;
  vertical-align: middle;
}

.fc-event {
  position: relative;
  display: block;
  font-size: 0.85em;
  line-height: 1.3;
  border-radius: 3px;
  border: 0px solid $primary !important;
}

a.fc-day-grid-event {
  background: $primary;
  padding: 5px;
}

th.fc-day-header {
  border-bottom-width: 2px;
  padding: 10px 0px;
  display: table-cell;
  background: #f5f5f5;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

td.fc-head-container {
  padding: 0px !important;
}

.fc-toolbar h2 {
  margin: 0;
  font-weight: bold;
}

span.fa {
  font-family: 'iconsmind' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

span.fa.fa-chevron-left:before {
  content: '\f077';
}

span.fa.fa-chevron-right:before {
  content: '\f07d';
}

// CUSTOM
.company-calendar {
  .fc-header-toolbar {
    // display: none !important;
  }
  .fc-theme-standard {
    .fc-col-header-cell-cushion {
      text-transform: uppercase;
    }
  }
  .select-month,
  .select-year {
    background: none;
    height: auto !important;
    font-size: 24px;
    font-weight: 700;
    background-position-y: 10px !important;
    text-align: right;
    padding-right: 25px;
  }
  .select-year {
    width: 95px;
  }
  .fc-daygrid-day {
    &:first-child,
    &:last-child {
      background-color: #f2f2f2;
    }
    &.fc-day-other {
      background-color: #b3b3b3;
    }
    .fc-daygrid-day-number {
      color: #000;
      font-weight: 400;
      font-size: 12px;
      font-family: $font-family-base;
      text-transform: uppercase;
    }
    .fc-daygrid-day-top {
      justify-content: start;
      opacity: 1 !important;
    }
  }
  .fc-col-header-cell-cushion {
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }
  .fc-daygrid-day-frame {
    padding: 10px;
  }
}

.calender {
  .btn-today {
    border-radius: 20px;
    border-color: #00cdc2;
    padding: 5px 22px;
    margin-right: 10px;
    font-size: 14px;


    &:hover {
      color: white;
      background-color: #00B9AF !important;
      border-color: none !important;
      box-shadow: none !important;
    }

    &:active {
      background-color: #E5FAF9 !important;
      color: #00CDC2 !important;
      border: 1px solid #00CDC2 !important;
      box-shadow: none !important;
      outline: none;
      box-shadow: 0 0 0 5px #B3F0ED !important; 
    }

    &:focus {
      background-color: #E5FAF9 !important;
      color: #00CDC2 !important;
      border: 1px solid #00CDC2 !important;
      box-shadow: none !important;
      outline: none;
      box-shadow: 0 0 0 5px #B3F0ED !important; 
    }
  }
  .rbc-btn-group {
    button {
      border: none;
      color: rgba(0, 0, 0, 1);
    }
    select {
      width: 85px;
      padding: 5px 12px;
      border-radius: 20px;
      border-color: #00cdc2;
      background-position-x: 95% !important;
      padding-left: 0px;
      text-align: center;
      &:focus-visible {
        outline: none;
      }
    }
  }
  .rbc-toolbar-label {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left !important;
  }
  .rbc-allday-cell {
    display: none !important;
  }

  .rbc-header {
    border-bottom: none !important;
    button {
      margin-bottom: 20px;
      div {
        color: rgba(0, 0, 0, 1);
        div:nth-child(1) {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
        }
        div:nth-child(2) {
          font-size: 30px;
          font-weight: 500;
          line-height: 42px;
        }
      }
    }
    &.rbc-today {
      background-color: white;
      button {
        div {
          div:nth-child(2) {
            border-radius: 20px;
            background: rgba(11, 70, 80, 1);
            color: rgba(255, 255, 255, 1);
            width: 42px;
            height: 42px;
          }
        }
      }
    }
  }

  .rbc-time-view {
    border-top: 0 !important;
    border-left: 0 !important;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 0 !important;
  }
  .rbc-time-header-gutter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
    background-color: #fff;
  }

  .rbc-current-time-indicator {
    height: 0 !important;
  }
  .rbc-events-container {
    & > div {
      border: none;
      padding: 10px;
      font-size: 10px;
      font-weight: 400;
      color: #000;
      &:has(.new-event) {
        background-color: rgba(204, 204, 204, 1);
      }
      &:has(.old-event) {
        background-color: rgba(204, 204, 204, 1);
      }
      &:has(.edit-event) {
        background-color: rgba(208, 197, 251, 1);
      }
    }
    & > div.rbc-selected {
      &:has(.new-event) {
        background-color: rgba(208, 197, 251, 1);
      }
    }
  }
}

.edit-room-booking-modal {
  .modal-dialog.modal-dialog-centered {
    max-width: 800px !important;
  }
  .event-popover-custom {
    padding: 20px;
  }
}
.event-popover-custom {
  .date-time-wrapper {
    gap: 20px;
    margin-bottom: 20px;
    .date-time {
      font-size: 18px;
      line-height: 18px;
    }
    .gutter-header {
      font-size: 13px;
      line-height: 18px;
      margin-top: 5px;
    }
  }
  .location {
    margin-bottom: 20px;
    gap: 20px;
    .room {
      font-size: 18px;
      line-height: 18px;
    }
  }
  .date-time-edit {
    margin-right: 0;
    margin-left: 0;
    & > div label {
      margin-bottom: 10px;
    }
    .input-item {
      &:first-child {
        padding-left: 0;
      }
      & > div {
        background-color: rgba(229, 229, 229, 1);
      }
      &.date-time {
        display: flex;
        flex-direction: column;
        & > div {
          height: 40px;
          display: flex;
          padding-left: 12px;
          justify-content: start;
          border-radius: 5px;
        }
      }
    }
    .keclick-date-picker-container {
      width: 100%;
      .icon {
        display: none;
      }
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
    button {
      border-radius: 20px;
      padding: 5px 22px;
      width: 160px;
      font-size: 14px;
    }
  }
}

.dropdown-menu-slottime {
  .time-option {
    padding: 3px;
    padding-left: 10px;
    font-size: 14px;
    color: #000;
    &:hover {
      background: rgba(229, 229, 229, 1);
    }
  }
}

.rbc-time-header-content {
  .rbc-row.rbc-row-resource {
    border-bottom: none !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.room-booking-calender-filter,
.desk-booking-calender-filter {
  .input-calendar {
    width: 21%;
  }
  .info-calendar {
    width: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -10px;
  }
  .input-item {
    width: 24%;
  }
  .info-input {
    width: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -10px;
  }
  .search-btn {
    width: 13%;
  }
}
