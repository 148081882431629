.ul-faq__details {
  margin: 70px 0;
}
.ul-faq__list-details ul {
  padding-left: 10px;
  margin: 0;
}
.ul-faq__list-details ul li {
  list-style: none;
  color: $gray-700;
  padding: 5px 0;
  font-weight: 700;
}
