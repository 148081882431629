.employee-wrap {
  .buttons-navigation {
    gap: 40px;
    margin-bottom: 40px;

    .btn-navigation {
      min-height: 80px;
      width: 160px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      color: $asure-neutral-70;
      cursor: pointer;

      &.active {
        background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
        color: $asure-secondary-100;
      }
    }
  }

  .employee-status {
    &.active {
      color: $asure-success-100;
    }

    &.resigned {
      color: #000;
    }
    &.pending.authentication {
      color: #e52626;
    }
  }

  .overview {
    .avatar {
      width: 158px;
      height: 158px;
      object-fit: cover;
    }
  }

  .working-hours {
    .working-hour-item {
      border: 1px solid $asure-neutral-70;
      border-radius: 12px;
      padding: 20px;
      margin-bottom: 20px;
      height: calc(100% - 24px);
    }
  }

  .availability {
    font-weight: 700;
    font-size: 12px;

    &.inuse {
      color: $asure-secondary-100;
    }
  }

  .validity {
    font-weight: 700;
    font-size: 12px;

    &.pending {
      color: $asure-tertiary-100;
    }

    &.valid {
      color: $asure-success-100;
    }
  }

  .payment {
    font-weight: 700;
    font-size: 12px;

    &.unpaid {
      color: $asure-error-100;
    }

    &.paid {
      color: $asure-success-100;
    }
  }

  .approvalStatus {
    font-weight: 700;
    font-size: 12px;

    &.pending {
      color: $asure-tertiary-100;
    }

    &.approved {
      color: $asure-success-100;
    }

    &.denied {
      color: $asure-error-100;
    }
  }

  .reimburseStatus {
    font-weight: 700;
    font-size: 12px;

    &.paid {
      color: $asure-success-100;
    }

    &.unpaid {
      color: $asure-error-100;
    }
  }

  .appraisalStatus {
    font-weight: 700;
    font-size: 12px;

    &.pending-employee {
      color: $asure-error-100;
    }

    &.pending-reviewer {
      color: $asure-tertiary-100;
    }

    &.completed {
      color: $asure-success-100;
    }
  }

  .selection-cell-header {
    width: 50px !important;
  }
}

@media (min-width: 1024px) {
}

@media (max-width: 767px) {
  .employee-wrap {
    .buttons-navigation {
      flex-direction: column;

      .btn-navigation {
        width: 100%;
        min-height: auto;
      }
    }
  }
}
