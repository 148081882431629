.modal-module {
  &.change-plan-modal {
    font-family: 'Open Sans', sans-serif !important; 
    .ant-modal-content {
      border-radius: 16px;
    }
  }
  top: 5%;
  .ant-modal-content {
    padding: 25px;
  }
  button {
    &.btn-cancel {
      width: 120px!important;
      height: 48px!important;
      padding: 0;
      border: 1px solid #00cdc2;
      background-color: #ffffff !important;
      color: #00cdc2;
      &:hover{
        background-color: #e5faf9 !important;
      }
    }
    &.ant-btn {
      padding: 5px 40px !important;
    }
    &:disabled{
      background-color: #E5E5E5 !important;
      color: #999999 !important;

    }
  }
  .modal-footer {
    padding-right: 0;
    .btn-save {
      width: 120px!important;
      height: 48px!important;
      padding: 0;
      margin-right: 0;
    }
    .btn {
      font-weight: 600;
      font-size: 16px;
    }
  }
  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
  }
  .content {
    label {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }
  .form-label {
    flex: 1;
  }
  .form-item-input {
    margin-top: 10px;
    padding: 15px 20px;

    &::placeholder {
      color: rgba(128, 128, 128, 1);
      font-size: 14px;
      font-weight: 400;
    }
  }
  .card-number {
    .StripeElement {
      width: 255px;
    }
  }
  .form-item {
    width: 100%;
    background: rgba(242, 242, 242, 1);
    padding: 12px 20px 12px 20px;
    gap: 15px;
    border-radius: 30px;
    margin-top: 10px;
  }
  label {
    margin-bottom: 10px;
  }
  input,
  textarea {
    border-radius: 30px;
    background-color: #f2f2f2;
  }
  input {
    width: 100%;
    height: 48px;
  }
  textarea {
    height: 84px !important;
    padding: 12px 20px;
  }
  .form-group,
  hr {
    margin-bottom: 30px;
  }
  .ant-switch {
    margin-right: 10px;
  }
  .checkmark {
    margin-right: 12px;
  }
  .modal-title {
    font-size: 20px;
  }
  .modal-body {
    padding: 0;
    .row {
      line-height: 25px;
    }
  }
  .modal-footer {
    border-top: none !important;
  }
}
.organization-plan-option {
  padding-left: 15px;
  padding-right: 15px;
  width: 30%;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  .org-plan-wrapper {
    padding: 30px 24px;
    border: 1px solid #cccccc;
    border-radius: 16px;
    height: 100%;
  }
  .text-custom {
    color: #00cdc299;
    background-color: #ebfbfa;
    border: 1px solid #00cdc299;
    border-radius: 24px;
    padding: 2px 2px;
    font-size: 10px;
    margin-left: 5px;
  }

  .org-plan-container {
    height: 90%;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .feature-wrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 10px;
  }

  .plan-feature {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 5px;
    font-size: 16px;
  }

  .btn-select-plan {
    width: 100%;
  }
}

.table-plan {
  table {
    thead {
      background-color: #f8f8f8;
      th {
        border: none;
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
.plan-notice {
  border-radius: 40px;
  background-color: #f2f2f2;
  padding: 15px 24px;
  color: #333333;
}
.plan-warning {
  img {
    margin-right: 10px;
  }
}
.button-notice {
  border: 1px solid #d30000;
  color: #d30000;
  &.btn-confirm,
  &:hover {
    background-color: #d30000 !important;
    color: #ffffff;
  }
}
.card-element {
  width: 450px;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding: 15px 20px;
  cursor: pointer;
  .column-1 {
    padding-right: 10px;
    padding-top: 5px;
    width: 48px;
  }
  .column-2 {
    padding-right: 10px;
    width: 265px;
  }
  .column-4 {
    padding-right: 20px;
    padding-top: 5px;
    max-width: 120px;
  }
  .column-5 {
    padding-right: 10px;
    padding-top: 5px;
    width: 110px;
  }
  .icon-delete {
    cursor: pointer;
  }
  &.active {
    border: 2px solid #00cdc2;
  }
  .icon-hide {
    visibility: hidden;
  }
}
.list-plans {
  overflow-y: hidden;
  padding-bottom: 20px;
}
.plan-name {
  margin-right: 10px;
}
.plan-custom {
  color: #00cdc2;
  background-color: #ebfbfa;
  border: 1px solid #00cdc2;
  border-radius: 16px;
  opacity: 0.6;
  padding: 5px 10px;
  font-size: 12px;
}
.plan-icon {
  width: 32px;
}
.mright-30 {
  margin-right: 30px;
}
.card-input-cvv{
  position: relative;
  .icon-tooltip{
    position: absolute;
    right: 12px;
    top: 10px
  }
}
#tooltip > .tooltip-inner {
  background-color: #ffffff!important;
  color: #000!important;
  border: 1px solid #ffffff!important;
  border-radius: 15px;
  padding: 12px;
  opacity: 1;
}

#tooltip > .tooltip-arrow {
  border: none!important;
}