.asset-wrap {
  .availability {
    &.available {
      color: $asure-success-100;
    }
    &.inuse {
      color: $asure-secondary-100;
    }
    &.underrepairservicing {
      color: $asure-error-100;
    }
  }
}

@media (min-width: 1024px) {
}
@media (max-width: 767px) {
}
