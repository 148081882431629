.select-approval-status-style {
  background-color: #e5e5e5;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;

  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}

.option-Pending {
  color: #ffb600;
}

.option-Approved {
  color: #3cc13b;
}

.option-Cancelled {
  color: #e20c00;
}

.staff-year-datepicker {
  background-color: #e5e5e5;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  .react-datepicker-wrapper {
    width: 90%;

    .react-datepicker__input-container {
      width: 100%;

      .custome-date-picker {
        width: 100%;
        background-color: transparent;
        border: none;
      }
    }
  }
}

.staff-claim-card-body {
  overflow: hidden;
  .card-body {
    padding: 0 !important;
  }
}

.staff-leaves-entit-title {
  border-bottom: 1px solid #dee2e6;
  &.bg-gray {
    background-color: #e5e5e5;
  }
  p {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    padding: 10px 0px;
    margin-bottom: 0;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
}

.staff-approved-status {
  border-radius: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;

  &.approved {
    color: #00cdc2;
    background-color: #e5faf9;
    border: 1px solid #00cdc2;
  }

  &.denied {
    color: #e20b00;
    border: 1px solid #e20b00;
    background-color: #feebeb;
  }

  &.pending {
    color: #000;
    border: 1px solid #000;
    background-color: #f2f2f2;
  }

  &.available {
    color: $asure-success-100;
  }
}

.leave-carry-forward {
  .selection-cell-header {
    vertical-align: middle;
  }
}
table:has(.leave-carry-forward) {
  .leave-manual {
    background-color: #e5e5e5 !important;
  }
  tbody {
    td.avatar {
      img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }
    }
  }
}

.leave-carried-dialog {
  .modal-dialog {
    max-width: 1263px !important;
    .table-search-bar {
      input {
        border-radius: 5px;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1392px) {
  .product-prorate {
    position: relative;
    top: 10px;
  }
}

@media (min-width: 1393px) and (max-width: 1462px) {
  .product-prorate {
    position: relative;
    top: 10px;
  }
}

@media (min-width: 1393px) and (max-width: 1498px) {
  .product-prorate.whole {
    position: relative;
    top: 10px;
  }
}

@media (min-width: 1393px) and (max-width: 1498px) {
  .product-prorate.whole {
    position: relative;
    top: 10px;
  }
}

@media (min-width: 1499px) and (max-width: 1568px) {
  .product-prorate.whole {
    position: relative;
    top: 10px;
  }
}

@media (max-width: 1192px) {
  .product-prorate {
    position: relative;
    top: 10px;
  }
}

@media (max-width: 1432px) and (min-width: 1369px) {
  .product-prorate-day:not(.whole) {
    position: relative;
    top: 10px;
  }
}

@media (max-width: 1368px) and (min-width: 1333px) {
  .product-prorate-day:not(.whole) {
    position: relative;
    top: 10px;
  }
}

@media (max-width: 1538px) and (min-width: 1469px) {
  .product-prorate-day.whole {
    position: relative;
    top: 10px;
  }
}

@media (max-width: 1468px) and (min-width: 1428px) {
  .product-prorate-day.whole {
    position: relative;
    top: 10px;
  }
}
