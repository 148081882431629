.support-detail-card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-top: 1px solid rgba(229, 229, 229, 1);
  width: 100%;
  &-info {
    padding: 40px;
    max-width: 260px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &-comment {
    padding: 40px;
    padding-right: 0;
    flex-shrink: unset;
    .btn-primary:disabled {
      background-color: rgba(229, 229, 229, 1);
      color: rgba(153, 153, 153, 1);
      border: none;
    }
    textarea {
      padding: 15px 20px;
    }
    label {
      font-size: 14px;
    }
    &-label {
      font-size: 14px;
      margin-bottom: 10px;
    }
    &-changesize {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.support-ticket-form {
  margin-top: 30px;
  &-label {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px;
  }
  &-content {
    padding: 15px 20px;
  }
}

.support-ticket-detail {
  &-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
  &-value {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    width: 1000px !important;
  }
  &-row {
    margin-top: 12px;
  }
}

.support-close-ticket {
  .swal2-styled:focus {
    box-shadow: none !important;
  }
}