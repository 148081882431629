.ul-card__widget-chart {
  padding: 0px;
}
.ul-widget__chart-info {
  padding: 15px;
}
.ul-widget__chart-number {
}
.ul-widget__row {
  align-items: center;
  display: flex;
}
.ul-widget__content {
  margin-left: 30px;
}

.ul-widget__row-v2 {
  text-align: center;
  text-align: -webkit-center;
}

.ul-widget-stat__font i {
  font-size: 35px;
}
.ul-widget__content-v4 {
  text-align: center;
}
