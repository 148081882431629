.overlay-suspend {
  .popover-arrow {
    &::before {
      border-width: 0;
      border-style: none;
      content: none;
    }
    &::after {
      border-width: 0;
      border-style: none;
      content: none;
    }
  }
  .suspend-account {
    box-shadow: 0px 0px 10px 0px rgba(155, 163, 172, 0.15);
    padding: 16px;
    border-radius: 16px;
    width: 180px;
    cursor: pointer;
  }
}

.manage-admin-user {
  .header {
    font-size: 16px;
    font-weight: 600;
    &.entity {
      margin-top: 60px;
    }
  }
  input,
  select {
    border-radius: 30px !important;
  }
}

.buttons-navigation-manage-entities {
  gap: 20px;
  margin-bottom: 20px;
  .btn-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    font-weight: 600;
    font-size: 16px;
    color: $asure-neutral-70;
    cursor: pointer;
    &.active {
      border: 1px solid $asure-secondary-100;
      color: $asure-secondary-100;
    } 
  }
}