table {
  &.dataTable-collapse {
    border-collapse: collapse !important;
  }
}

.react-bootstrap-table-pagination {
  .dropdown-toggle::after {
    content: none;
    display: none !important;
  }
}

@media (max-width: 767px) {
  .react-bootstrap-table {
    width: 100%;
    overflow-x: scroll;
    table {
      table-layout: auto;
    }
  }
}
