.dashboard-layout-wrap {
  .card-body.welcome,
  .slider-announcement {
    height: 140px;
  }
  .slider-announcement {
    background-color: $asure-neutral-80;
    flex-direction: column;
    .carousel {
      margin-top: 5px;
      width: 100%;
      height: 100%;
      .carousel-inner,
      .carousel-item {
        height: 100%;
        .carousel-caption {
          position: absolute;
          right: 0;
          bottom: unset;
          left: 0;
          padding-top: 0;
          padding-bottom: 0;
          color: #fff;
          text-align: left;
        }
      }
      .carousel-indicators {
        top: -20px;
        bottom: unset;
        left: unset;
        right: 0;
        margin-right: unset;
        margin-bottom: unset;
      }
    }
  }
  .statistic-value {
    color: $asure-primary-100;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
  .quick-links {
    li {
      margin-bottom: 10px;
      a {
        color: $asure-neutral-70;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .statistics {
    .simple-card-title {
      .text-capitalize {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.popup-day-in-calendar,
.shift-detail-in-calendar {
  .label {
    color: var(--Neutral-100, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .box {
    border: 1px solid $asure-neutral-70;
    border-radius: 12px;
    height: 100%;
    .header {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: 600;
        margin-left: 10px;
      }
    }
    thead.foo {
      background-color: #f2f2f2;
    }
    .table > :not(:last-child) > :last-child > * {
      border-bottom-color: transparent;
    }
    td,
    th {
      padding: 15px;
    }
  }
  .modal-header {
    border-bottom: none;
    .modal-title {
      font-size: 20px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 600;
    }
  }
  .modal-footer {
    border-top: none;
  }
}

@media (min-width: 1200px) {
  .popup-day-in-calendar {
    .modal-dialog {
      // max-width: calc(100% - 100px) !important;
      // .modal-body {
      //     padding: 20px 25px;
      // }

      max-width: calc(100% - 500px) !important;
      .modal-body {
        padding: 20px 25px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .popup-day-in-calendar {
    .modal-dialog {
      max-width: calc(100% - 200px);
    }
  }
}

@media (max-width: 767px) {
}
