@include keyframes(swing) {
  20%,
  40%,
  60%,
  80%,
  100% {
    @include transform-origin(top center);
  }
  20% {
    @include transform(rotate(15deg));
  }
  40% {
    @include transform(rotate(-10deg));
  }
  60% {
    @include transform(rotate(5deg));
  }
  80% {
    @include transform(rotate(-5deg));
  }
  100% {
    @include transform(rotate(0deg));
  }
}

@mixin swing(
  $count: $countDefault,
  $duration: $durationDefault,
  $delay: $delayDefault,
  $function: $functionDefault,
  $fill: $fillDefault,
  $visibility: $visibilityDefault
) {
  @include transform-origin(top center);
  @include animation-name(swing);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
