.claims-wrap {
  padding-bottom: 50px;

  .buttons-navigation {
    gap: 40px;
    margin-bottom: 40px;

    .btn-navigation {
      min-height: 80px;
      width: 240px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      color: $asure-neutral-70;
      cursor: pointer;

      &.active {
        background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
        color: $asure-secondary-100;
      }
    }
  }

  .react-bootstrap-table {
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 8px;
      visibility: hidden;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(221, 217, 217);
      outline: none;
      border-radius: 15px;
      visibility: hidden;
    }
  }

  .react-bootstrap-table table {
    table-layout: auto;
  }

  .claim-log-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .reimbursed-status {
    &.paid {
      color: $asure-success-100;
    }

    &.unpaid {
      color: $asure-error-100;
    }
  }

  .approved-status {
    background: #e5e5e5;
    border-radius: 15px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    background-position-y: 3px !important;

    &.approved {
      color: $asure-success-100;
    }

    &.denied {
      color: $asure-error-100;
    }

    &.pending {
      color: $asure-tertiary-100;
    }

    &.available {
      color: $asure-success-100;
    }
  }

  .staff-approved-status {
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;

    &.approved {
      color: #00cdc2;
      background-color: #e5faf9;
      border: 1px solid #00cdc2;
    }

    &.denied {
      color: #e20b00;
      border: 1px solid #e20b00;
      background-color: #feebeb;
    }

    &.pending {
      color: #000;
      border: 1px solid #000;
      background-color: #f2f2f2;
    }

    &.available {
      color: $asure-success-100;
    }
  }
}

.staff-claim-card-body {
  .card-body {
    padding: 0 !important;
  }
}

.staff-claims-entit-title {
  p {
    background-color: #f2f2f2;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    padding: 10px 0px;
    padding-left: 20px;
    margin-bottom: 0;
  }

  .item {
    font-size: 18px;
    font-weight: 500;
    padding: 5px 0px;
    padding-left: 50px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid #e5e5e5;
  }
}

.claims-detail-image-modal {
  display: flex;
  justify-content: center;
  position: relative;

  .icon-close {
    all: unset;
    position: absolute;
    right: -60px;
    top: -10px;
    background-color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
  }
}

.shift-planning-wrap {
  .specific-type {
    margin-right: 8px;
  }
}
