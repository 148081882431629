.ul-contact-list__image-title {
  display: flex;
  align-items: center;
}

.ul-link-action {
  font-size: 20px;
}
.ul-link-action i {
  margin-right: 5px;
}
