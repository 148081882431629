.interview-wrap {
  .total-payslip {
    thead {
      visibility: collapse;
    }
  }
  .employee-status {
    &.active {
      color: $asure-success-100;
    }

    &.resigned {
      color: #000;
    }
  }
}
.payroll-wrap {
  .date-range-picker-wrapper {
    .date-range-picker {
      width: 100%;
    }
  }
  .payroll-month .container {
    background: #e5e5e5;
  }
  .payroll-month .payroll-month__title {
    display: flex;
    justify-content: space-around;
    color: #000;
    font-size: 16px;
    margin-bottom: 37px;
  }
  .payroll-month .payroll-month__main {
    max-width: 760px;
    height: 130px;
    background: #f2f2f2;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .payroll-month .payroll-month__main .month {
    position: relative;
    width: 3px;
  }
  .payroll-month .payroll-month__main .month::before {
    content: '';
    position: absolute;
    width: 3px;
    height: calc(100% + 24px);
    bottom: 0;
    left: 0;
    background: #000;
  }
  .payroll-month .payroll-month__main .month-start::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 3px;
    top: -24px;
    left: 0;
    background: #000;
  }
  .payroll-month .payroll-month__main .month-end::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 3px;
    top: -24px;
    right: 0;
    background: #000;
  }
  .payroll-month .payroll-month__main .month span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .payroll-month .payroll-month__main .month-start span,
  .payroll-month .payroll-month__main .month-center span:nth-of-type(2) {
    left: 8px;
  }
  .payroll-month .payroll-month__main .month-end span,
  .payroll-month .payroll-month__main .month-center span:first-of-type {
    right: 8px;
  }
  .payroll-month .payroll-month__start {
    position: absolute;
    width: 25px;
    height: 168px;
    border: 3px solid #00cdc2;
    top: 0;
    left: calc((100% / 30) * 20);
  }
  .payroll-month .payroll-month__start:before,
  .payroll-month .payroll-month__end:before {
    content: '';
    width: 24px;
    height: 24px;
    background: url(/assets/images/payroll/icon-dola.svg) no-repeat center;
    position: absolute;
    bottom: -44px;
    left: -12px;
  }
  .payroll-month .payroll-month__start:after,
  .payroll-month .payroll-month__end:after {
    content: '';
    width: 0px;
    height: 27px;
    position: absolute;
    background: #00cdc2;
    left: -3px;
    bottom: -27px;
    border-left: 3px solid #00cdc2;
  }
  .payroll-month .payroll-month__start span {
    color: #00cdc2;
    margin-top: -28px;
    display: block;
    width: 40px;
  }
  .payroll-month .payroll-month__end {
    position: absolute;
    width: 25px;
    height: 168px;
    border: 3px solid #00cdc2;
    top: 0;
    left: calc((100% / 30) * 35);
  }

  .payroll-month .payroll-month__end span {
    color: #00cdc2;
    margin-top: -28px;
    display: block;
    width: 40px;
  }

  .payslip {
    .label {
      font-size: 16px;
      font-weight: 600;
      display: inline-flex;
    }
    .text {
      font-size: 16px;
    }
    .personal-details {
      display: flex;
      .label {
        width: 160px;
      }
    }
    .row-total {
      background-color: $asure-neutral-10;
      p {
        margin: 0;
        padding: 0;
      }
    }
    &.download {
      .block {
        padding: 0 20px;
        margin-bottom: 10px;
      }
      .label {
        font-size: 12px;
      }
      .text {
        font-size: 12px;
      }
      .line-seperate {
        width: 100%;
        height: 1px;
        background-color: $asure-neutral-70;
        margin: 10px 0;
      }
    }
  }
  .buttons-navigation {
    gap: 40px;
    margin-bottom: 40px;
    .btn-navigation {
      min-height: 80px;
      width: 190px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      color: $asure-neutral-70;
      cursor: pointer;
      &.active {
        background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
        color: $asure-secondary-100;
      }
    }
  }
  .payroll-period-graph {
    .graph {
      height: 131px;
      background: var(--neutral-10, #e5e5e5);
      &.payroll-month {
        background: var(--neutral-5, #f2f2f2);
      }
    }
  }
}

@media (min-width: 1024px) {
}
@media (max-width: 767px) {
  .payroll-wrap {
    .buttons-navigation {
      flex-direction: column;
      .btn-navigation {
        width: 100%;
        min-height: auto;
      }
    }
  }
}

.pay-scheme-template {
  .overtime_hours,
  .calculate-pay-based {
    .checkmark {
      top: 10px;
    }
  }
  .pro-rate {
    display: flex;
    align-items: center;
    .checkmark {
      &.disabled {
        background: #b3b3b3;
      }
    }
    span.disabled {
      color: #70657b !important;
    }
  }

  .timezone-wrapper {
    &-title {
      margin-top: 24px !important;
      font-size: 20px;
      color: var(--neutral-100, #000);
      font-weight: 600;
      line-height: 24px;
    }
    &-content {
      margin-top: 25px !important;
      margin-bottom: 40px !important;
    }
  }
}

.payroll-generate {
  select.payment-period {
    option:disabled {
      color: #969696 !important;
    }
  }
}

.monthly-period-border {
  position: relative;
  width: 300px; /* You can adjust the width as needed */
  height: 200px; /* You can adjust the height as needed */
  border: 1px solid #000;
}

.monthly-period-border::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  background-color: black; /* You can adjust the color as needed */
}
