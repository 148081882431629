.user-profile {
}

.form-actions {
  display: flex;
  gap: 42px;
  justify-content: end;
  .btn-cancel,
  .btn-save {
    min-width: 150px !important;
  }
}
.profile-banner-img {
  width: 380px;
  height: 423px;
  margin-bottom: 20px;
}
.profile-banner-text {
  width: 317px;
}
@media (max-width: 767px) {
  .form-actions {
    display: flex;
    gap: 10px;
    justify-content: end;
    .btn-cancel,
    .btn-save {
      min-width: auto;
    }
  }
}
