.organization-wrap {
  .buttons-navigation {
    gap: 40px;
    margin-bottom: 40px;
    .btn-navigation {
      min-height: 80px;
      width: 180px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      color: $asure-neutral-70;
      cursor: pointer;
      &.active {
        background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
        color: $asure-secondary-100;
      }
    }
  }
  .logo {
    max-height: 40px;
  }
  .rdt.form-control-rounded .form-control {
    border-radius: 0.25rem;
  }
  .org-item {
    padding: 12px 37px;
    background-color: $asure-neutral-5;
    border-radius: 12px;
  }
  .branch-status {
    &.active {
      color: $asure-success-100;
    }
    &.inactive {
      color: $asure-neutral-100;
    }
  }
  .working-hours {
    .working-hour-item {
      border: 1px solid $asure-neutral-70;
      border-radius: 12px;
      padding: 20px;
      margin-bottom: 20px;
      height: calc(100% - 24px);
    }
  }
}

@media (min-width: 1024px) {
}
@media (max-width: 767px) {
  .organization-wrap {
    .buttons-navigation {
      flex-direction: column;
      .btn-navigation {
        width: 100%;
        min-height: auto;
      }
    }
  }
}
