.auth-layout-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  .auth-content {
    max-width: 380px;
    margin: auto;
    a {
      color: $asure-secondary-100 !important;
      font-size: 14px;
      font-weight: 700;
    }
    &.signup {
      max-width: 842px;
      select {
        background-position-y: 13px !important;
      }
    }
    .form-control {
      border-radius: 5px !important;
    }
    .form-control,
    .btn {
      height: 50px !important;
    }
    label {
      color: #4d4d4d;
      font-size: 16px;
      font-weight: 600;
      font-family: 'Quicksand', sans-serif;
    }
    .link {
      font-size: 14px;
      font-weight: 700;
      color: $asure-secondary-100 !important;
    }
    .remember-me span {
      color: $asure-neutral-70;
      font-size: 14px;
      font-weight: 400;
    }
    .title {
      color: $asure-neutral-70;
      font-family: 'Quicksand', sans-serif;
    }
    .desc {
      font-weight: 400;
      line-height: 18px;
    }
    .verification-code {
      width: 100% !important;
      margin: auto;
      div {
        display: flex;
        gap: 50px;
        input {
          width: 40px !important;
          background-color: red;
          flex: 1;
          background-color: $asure-neutral-10;
          border-radius: 5px !important;
          border: none;
          font-weight: bold;
          font-size: 20px;
          font-family: 'Quicksand', sans-serif;
        }
      }
    }
    .toggle-password {
      margin-top: -65px;
      margin-left: calc(100% - 35px);
    }
  }
}
// new design

.auth-content-left {
  @media (max-width: 640px) {
    width: 100%;
    &.forgot-password {
      padding: 32px 16px;
      .logo {
        cursor: pointer;
        top: 32px;
        left: 16px;
      }
      .form {
        width: 100% !important;
        margin-top: 0px !important;
        .title-container {
          .font-weight-500 {
            font-size: 30px !important;
          }
          .font-weight-400 {
            font-size: 14px !important;
            color: #4d4d4d;
          }
        }
        .form-content {
          form {
            .reset-btn {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
            .form-item {
              margin-bottom: 20px !important;
              .label {
                text-transform: uppercase;
              }
            }
            .mt-3 {
              margin-top: 0 !important;
              label {
                color: #4d4d4d !important;
                font-weight: 500;
                margin-bottom: 8px !important;
              }
            }
            .mt-40 {
              margin-top: 20px !important;
            }
            .mt-10 {
              margin-top: 20px !important;
            }
            .enter-code {
              margin-top: 10px !important;
            }
            .code-input {
              margin-top: 0 !important;
              input {
                background-color: #fff;
                border: 1px solid #b3b3b3;
                &::placeholder {
                  font-family: 'Inter', sans-serif;
                  font-size: 40px !important;
                  font-weight: 500 !important;
                  line-height: 40px;
                  letter-spacing: -0.02em;
                  text-align: center;
                  color: #b3b3b3;
                }
                font-family: 'Inter', sans-serif;
                font-size: 40px !important;
                font-weight: 500 !important;
                line-height: 40px;
                letter-spacing: -0.02em;
                text-align: center;
                border-radius: 8px !important;
                &:focus {
                  border: 3px solid #02b9af;
                }
              }
            }
            .resend-code {
              text-decoration: none !important;
              font-size: 16px !important;
              margin-top: 16px !important;
              margin-bottom: 0px !important;
              line-height: 20px !important;
            }
            .remember-account {
              line-height: 18px !important;
              font-weight: 500 !important;
              font-size: 14px !important;
              color: #22272f;
              font-family: 'Quicksand';
            }
          }
        }
      }
    }
  }
  width: 50%;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .logo {
    position: absolute;
    cursor: pointer;
    top: 20px;
    left: 20px;
    width: 150px;
    height: 35px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .form {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 430px;
    margin: 0 auto;
    margin-top: 80px;
    font-family: $font-family-secondary;

    .segment-container {
      display: flex;
      border: 1px solid $asure-neutral-30;
      border-radius: 100px;
      padding: 3px;
      width: 100%;
      height: 48px;

      .custom-segment {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 100px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-family: $font-family-secondary;
        font-size: 16px;
        font-weight: 600;

        &.selected {
          background-color: #0d4f50;
          color: #fff;

          .segment-label {
            color: #fff;
          }
        }

        .segment-label {
          font-size: 16px;
          font-weight: 600;
          color: black;
        }
      }
    }

    .google-login {
      .button-login {
        cursor: pointer !important;
        all: unset;
        width: 100%;
        border-radius: 100px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 12px 0px;
        max-height: 48px;
        background-color: $asure-neutral-5;
        margin-bottom: 30px;
        color: $asure-neutral-70;
        font-size: 16px;
        font-weight: 500;
      }

      .divider {
        display: flex;
        align-items: center;
        text-align: center;
        margin: 20px 0;
        width: 100%;

        &::before,
        &::after {
          content: '';
          width: 45%;
          border-bottom: 1px solid $asure-neutral-40;
          z-index: -1;
        }

        &::before {
          margin-right: 10px;
        }

        &::after {
          margin-left: 10px;
        }

        span {
          font-size: 12px;
          background-color: white;
          padding: 4px 24px;
          display: flex;
          font-weight: 500;
          align-items: center;
          justify-content: center;
          color: $asure-neutral-30;
        }
      }
    }

    .verification-code {
      width: 100% !important;
      margin: auto;
      div {
        display: flex;
        gap: 15px;
        input {
          width: 40px !important;
          flex: 1;
          background-color: $asure-neutral-10;
          border-radius: 5px !important;
          border: none;
          font-weight: bold;
          font-size: 20px;
          font-family: 'Quicksand', sans-serif;
        }
      }
    }

    .title-container {
      text-align: left;
      margin-top: 20px;
      font-family: 'Quicksand', sans-serif;
      width: 100%;

      div {
        font-family: 'Quicksand', sans-serif;
        color: $neutral-90;
        &.text-neutral-70 {
          color: $neutral-70;
        }
      }
      .signin-title {
        font-size: 48px;
      }
      .signin-intro {
        font-size: 16px;
      }
    }
    .form-wrapper {
      .form-group {
        label {
          color: $asure-neutral-70;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 10px;
          line-height: 20px;
        }
      }
      .remember {
        label {
          margin-bottom: 0;
          .checkmark {
            border: 2px solid $asure-neutral-50;
          }
        }
      }
      .login-btn {
        padding: 12px 24px;
        height: auto;
        span {
          font-size: 16px;
        }
      }
      .login-footer {
        span {
          font-size: 16px;
        }
      }
    }
  }
}
.auth-content-right {
  @media (max-width: 640px) {
    display: none;
  }
  width: 50%;
  background-color: $primary-100;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 90%;
    height: auto;
  }

  .logo-solid {
    position: absolute;
    width: 150px;
    height: 35px;
    bottom: 20px;
    left: 20px;
    min-width: 150px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.auth-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.auth-logo {
  img {
    width: 193px;
  }
}

@media (min-width: 1024px) {
  .auth-layout-wrap {
    .auth-content {
      min-width: 400px;
      .signup {
        position: relative;
        &:before {
          content: '';
          background: #b3b3b3;
          width: 2px;
          position: absolute;
          left: 50%;
          height: calc(100% - 25px);
        }
        .col-md-6 {
          padding: 0 30px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .auth-layout-wrap {
    .auth-content {
      padding: 15px;
      width: 100%;
      .verification-code {
        div {
          gap: 10px;
        }
      }
    }
  }
  .auth-right {
    padding: 80px 15px;
  }
}

.config-org-layout-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;

  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 150px;
    height: 35px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .auth-content {
    max-width: 380px;
    margin: 0px auto;
    a {
      color: $asure-secondary-100 !important;
      font-size: 14px;
      font-weight: 700;
    }
    &.signup {
      max-width: 842px;
      select {
        background-position-y: 8px !important;
      }
    }
    .form-control {
      border-radius: 30px !important;
    }
    .form-control,
    .btn {
      height: 40px !important;
    }
    label {
      color: #4d4d4d;
      font-size: 16px;
      font-weight: 600;
      font-family: 'Quicksand', sans-serif;
    }
    .link {
      font-size: 14px;
      font-weight: 700;
      color: $asure-secondary-100 !important;
    }
    .remember-me span {
      color: $asure-neutral-70;
      font-size: 14px;
      font-weight: 400;
    }
    .title {
      color: $asure-neutral-70;
      font-family: 'Quicksand', sans-serif;
    }
    .desc {
      font-weight: 400;
      line-height: 18px;
    }
    .verification-code {
      width: 100% !important;
      margin: auto;
      div {
        display: flex;
        gap: 50px;
        input {
          width: 40px !important;
          background-color: red;
          flex: 1;
          background: #fff;
          border: 1px solid #b3b3b3;
          border-radius: 5px !important;
          border: none;
          font-weight: bold;
          font-size: 20px;
          font-family: 'Quicksand', sans-serif;
        }
      }
    }
    .toggle-password {
      margin-top: -65px;
      margin-left: calc(100% - 35px);
    }
  }

  @media screen and (max-width: 480px) {
    justify-content: flex-start;
    align-items: flex-start;
    .auth-content {
      margin-top: 80px;
    }

    .survey-option {
      flex-direction: row !important;
      .text {
        font-size: 14px !important;
        width: 90%;
      }
    }

    .survey-footer {
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-end !important;

      .btn-skip {
        max-width: 150px !important;
        font-size: 14px !important;
      }
    }
    .setup-title-org {
      margin-top: 38px;
    }
  }
}

.input-field-redesign {
  background-color: white;
  border: 1px solid $asure-neutral-30;
  min-height: 48px;

  &:active {
    border: 1px solid $primary-100 !important;
    outline: none !important;
    box-shadow: none !important;
    outline: 1px solid $primary-100 !important;
  }

  &:focus {
    border: 1px solid $primary-100 !important;
    outline: none !important;
    box-shadow: none !important;
    outline: 1px solid $primary-100 !important;
  }
}

@media (max-width: 640px) {
  .setup-org {
    .content-wrapper {
      .btn-go-back {
        padding-left: 0 !important;
        text-align: left !important;
        img {
          width: 24px !important;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .page-signin {
    .auth-content-left {
      width: 100%;
      .form {
        justify-content: normal;
        margin-top: 102px;
        width: 94%;
        .title-container {
          .signin-title {
            font-size: 30px;
          }
          .signin-intro {
            font-size: 14px;
          }
        }
        .google-login {
          .button-login {
            font-size: 14px;
          }
        }
        .form-wrapper {
          .form-group label {
            font-size: 14px;
            margin-bottom: 8px;
          }
          .form-group .form-control {
            font-size: 14px;
          }
          .login-btn {
            height: 46px !important;
            span {
              font-size: 14px;
            }
          }
          .login-footer {
            span {
              font-size: 14px;
              width: 100%;
              display: inline-block;
              margin: 0 auto;
              text-align: center;
            }
          }
          .footer-span {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .input-style {
    font-size: 16px !important;
  }

  .setup-org {
    max-width: unset !important;
    width: 94%;

    .content-wrapper {
      width: 100% !important;
      margin-bottom: 20px !important;

      .btn-go-back {
        width: 45px;
        height: 45px;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      .btn-skip {
        color: $neutral-90 !important;
      }
    }

    .setup-title {
      font-size: 20px !important;
    }

    .setup-subtitle {
      font-size: 14px !important;
    }

    .setup-footer {
      float: right;
      max-width: 150px;

      .btn-continue {
        font-size: 14px !important;
      }
    }
  }
  .kelick-alert {
    left: 50% !important;
    top: 46px !important;
  }
  .not-found-wrap {
    max-width: 94% !important;
    margin: 0px auto !important;
    padding: 70px 0px !important;
    height: auto !important;
  }

  .verification-code {
    div {
      input {
        font-size: 20px !important;
        color: $neutral-90 !important;
        font-weight: 700 !important;
      }
    }
  }
}
