@include keyframes(zoomInDown) {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    @include transform(scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0));
  }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    @include transform(scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0));
  }
}

@mixin zoomInDown(
  $count: $countDefault,
  $duration: $durationDefault,
  $delay: $delayDefault,
  $function: $functionDefault,
  $fill: $fillDefault,
  $visibility: $visibilityDefault
) {
  @include animation-name(zoomInDown);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
