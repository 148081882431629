// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@include keyframes(wobble) {
  0% {
    @include transform(translateX(0%));
  }
  15% {
    @include transform(translateX(-25%) rotate(-5deg));
  }
  30% {
    @include transform(translateX(20%) rotate(3deg));
  }
  45% {
    @include transform(translateX(-15%) rotate(-3deg));
  }
  60% {
    @include transform(translateX(10%) rotate(2deg));
  }
  75% {
    @include transform(translateX(-5%) rotate(-1deg));
  }
  100% {
    @include transform(translateX(0%));
  }
}

@mixin wobble(
  $count: $countDefault,
  $duration: $durationDefault,
  $delay: $delayDefault,
  $function: $functionDefault,
  $fill: $fillDefault,
  $visibility: $visibilityDefault
) {
  @include animation-name(wobble);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
