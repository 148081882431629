.booking-status-booked {
  color: #ffb600;
}

.booking-status-progress {
  color: #3cc13b;
}

.booking-status-end {
  color: #4d4d4d;
}

.padding-form {
  padding: 0.375rem 0.75rem;
}

.datepicker-style {
  display: flex;
  justify-content: space-between;
  .react-datepicker-wrapper {
    width: 90%;
  }
}

.rangepicker-style {
  display: flex;
  justify-content: space-between;
  position: relative;

  .ant-picker-range {
    width: 100%;
    background: #e5e5e5;
    border: none;

    .ant-picker-input {
      width: 90px;
    }

    .ant-picker-suffix {
      position: absolute;
      right: 0;
    }
  }
}

.deskbooking-datetime-style {
  &.disabled {
    background-color: #b3b3b3 !important;
    .ant-picker {
      background-color: #b3b3b3 !important;
      .ant-picker-input {
        input {
          color: #47404f;
        }
      }
    }
  }
  // background-color: #e5e5e5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  .ant-picker {
    width: 90%;
    background-color: #e5e5e5;
    border: none;
  }
}

.deskbooking-map-style {
  width: 75%;
  margin: 50px auto;

  .blank-stage-wrapper {
    width: 70%;
    margin: 0 auto;
    .black-screen {
      width: 80%;
      background-color: black;
      height: 20px;
      margin: 0 auto;
    }

    .blank-stage {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid black;

      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
  }

  .deskbooking-position-wrapper {
    margin-top: 90px;
    .line-one {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-table {
        display: flex;
        gap: 150px;
      }

      .right-table {
        display: flex;
        gap: 150px;
      }
    }

    .line-two {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-table,
      .right-table {
        display: flex;
        flex-direction: column;
        gap: 50px;
      }
    }
  }
}

.square-table {
  display: flex;
  gap: 5px;

  .table-custom {
    border: 2px solid black;
    width: 100px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chairLine {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
  }
}

.circle-wrapper-deskbooking {
  // background-color: blue;

  position: relative;
  .circle {
    width: 200px;
    height: 200px;
    // border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .item-1 {
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .item-2 {
      top: 12.5%;
      left: 87.5%;
      transform: translateX(-50%) translateY(-50%);
    }

    .item-3 {
      top: 50%;
      left: 100%;
      transform: translateX(-50%) translateY(-50%);
    }

    .item-4 {
      top: 87.5%;
      left: 87.5%;
      transform: translateX(-50%) translateY(-50%);
    }

    .item-5 {
      top: 100%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .item-6 {
      top: 87.5%;
      left: 12.5%;
      transform: translateX(-50%) translateY(-50%);
    }

    .item-7 {
      top: 50%;
      left: 0;
      transform: translateX(-50%) translateY(-50%);
    }

    .item-8 {
      top: 12.5%;
      left: 12.5%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .smaller-circle {
    border: 2px solid black;
    width: 140px;
    height: 140px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.chairCircleDeskbooking {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  .chairCircleRotateDeskbookingImageWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      border-radius: 5px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border: 2px solid black;
      transform: rotate(45deg);
      z-index: -1;
      overflow: hidden;
    }
  }

  .chairCircleNotRotateDeskbookingImageWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      border-radius: 5px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border: 2px solid black;
      z-index: -1;
      overflow: hidden;
    }
  }
}

.chairSquareDeskBooking {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid black;

  img {
    width: 50px;
    height: auto;
  }
}

.deskbooking-modal-confirm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bookingSlot-features {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .slotItem {
    padding: 5px;
    background: linear-gradient(0deg, #ecffec, #ecffec), #ecffec;
    border: 1px solid #63cd62;
    border-radius: 5px;
  }
}

.date-picker-desk-booking {
  width: 90%;
  margin-left: 10px;
  margin-top: 5px;
}

.deskbooking-error-msg {
  button.confirm.swal2-styled.confirm.swal2-confirm {
    background-color: red !important;
  }
}
