// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@include keyframes(jello) {
  11.1% {
    @include transform(none);
  }
  22.2% {
    @include transform(skewX(-12.5deg) skewY(-12.5deg));
  }
  33.3% {
    @include transform(skewX(6.25deg) skewY(6.25deg));
  }
  44.4% {
    @include transform(skewX(-3.125deg) skewY(-3.125deg));
  }
  55.5% {
    @include transform(skewX(1.5625deg) skewY(1.5625deg));
  }
  66.6% {
    @include transform(skewX(-0.78125deg) skewY(-0.78125deg));
  }
  77.7% {
    @include transform(skewX(0.390625deg) skewY(0.390625deg));
  }
  88.8% {
    @include transform(skewX(-0.1953125deg) skewY(-0.1953125deg));
  }
  100% {
    @include transform(none);
  }
}

@mixin jello(
  $count: $countDefault,
  $duration: $durationDefault,
  $delay: $delayDefault,
  $function: $functionDefault,
  $fill: $fillDefault,
  $visibility: $visibilityDefault
) {
  @include animation-name(jello);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
