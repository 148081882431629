.super-admin-header {
  z-index: 130 !important;
  background-color: #e5e5e5;

  .logo {
    width: auto !important;
  }

  .header-link {
    font-size: 16px;
    line-height: 20px;

    a {
      color: #4d4d4d;
    }
  }
}

.master-admin-header {
  position: fixed;
  transform: translateY(80px);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100 !important;
  width: 100%;
  // background-color: #E5E5E5;

  .logo {
    width: auto !important;
  }

  .header-link {
    font-size: 16px;
    line-height: 20px;

    a {
      color: #4d4d4d;
    }
  }

  .user {
    margin-right: 2rem;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      cursor: pointer;
    }

    .dropdown-toggle.profile {
      display: flex;
      gap: 10px;
    }
  }

  &.active-bg {
    background: white;
  }
}

.main-header {
  position: fixed;
  width: 100%;
  height: $topbar-height;
  // box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  // background: $background;
  z-index: 100;

  .menu-toggle {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
    margin-left: 195px;
    margin-bottom: 20px;

    div {
      width: 24px;
      height: 1px;
      background: $foreground;
      margin: 3px 0;
    }

    span:after {
      display: inline-block;
      content: '\00d7';
      font-size: 40px;
      font-family: none;
    }
  }

  .search-bar {
    display: flex;
    align-items: center;
    justify-content: left;
    background: $gray-100;
    border: 1px solid $gray-200;
    border-radius: 20px;
    position: relative;
    width: 230px;
    height: 40px;

    input {
      background: transparent;
      border: 0;
      color: #212121;
      font-size: 0.8rem;
      line-height: 2;
      height: 100%;
      outline: initial !important;
      padding: 0.5rem 1rem;
      width: calc(100% - 32px);
    }

    .search-icon {
      font-size: 18px;
      width: 24px;
      display: inline-block;
    }
  }

  .logo {
    width: $sidebar-left-width;

    img {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      display: block;
    }
  }

  .show {
    .header-icon {
      background: $gray-100;
    }
  }

  .header-icon {
    font-size: 24px;
    cursor: pointer;
    height: 36px;
    width: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 8px;
    margin: 0 2px;

    &:hover {
      background: $gray-100;
    }

    &.dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }

  .header-part-right {
    display: flex;
    align-items: center;
    z-index: 999;
    position: relative;

    .user {
      margin-right: 2rem;

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        cursor: pointer;
      }

      .dropdown-toggle.profile {
        display: flex;
        gap: 10px;
      }
    }

    .text-content {
      width: 300px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      white-space: pre-line;
    }
  }

  .notification-dropdown {
    padding: 0;
    max-height: 260px;
    overflow-y: scroll;
    cursor: pointer;

    .dropdown-item {
      display: flex;
      padding: 10px 0 10px 10px;
      border-bottom: 1px solid $gray-300;

      .notification-icon {
        height: 100%;
        width: 44px;
        display: flex;
        align-items: start;
        justify-content: center;
        margin-top: 5px;

        i {
          font-size: 18px;
        }
      }

      .notification-details {
        padding: 0.25rem 0.75rem 0.25rem 0;
      }

      &:active {
        color: inherit;
        background: inherit;
      }
    }
  }
}

@media (max-width: 991px) {
  .main-header {
    .search-bar {
      width: 180px;
    }

    .menu-toggle {
      width: 24px;
      margin-right: 36px;
    }
  }
}

@media (max-width: 615px) {
  .main-header .header-part-right .user {
    margin-right: 0rem;
  }
}

@media (max-width: 580px) {
  .main-header {
    height: $topbar-height-mobile;
    padding: 0 1.5rem;

    .logo {
      width: 60px;
    }

    .search-bar {
      display: none;
    }

    .menu-toggle {
      width: 60px;
    }

    .header-part-right {
      .user {
        margin-right: 0;
        padding-right: 0;

        .dropdown-toggle.profile {
          display: inline;

          .name {
            display: none;
          }
        }
      }
    }
  }

  // .notification-dropdown {
  //   left: 10px !important;
  // }
}

@media (max-width: 360px) {
  .main-header {
    .menu-toggle {
      margin: 0;
    }
  }
}
