.process-step-wrap {
  display: flex;
  .step-item {
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    position: relative;
    &:before {
      height: 10px;
      background: #00cdc2;
      width: 100%;
      position: absolute;
      left: 0;
      top: 15px;
      content: '';
      z-index: 10;
    }
    &.active ~ .step-item::before {
      background: #cccccc;
    }
    .active ~ .step-item .button-circle {
      background: #cccccc;
    }
    .button-circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #00cdc2;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      margin: 0 auto 10px auto;
      position: relative;
      z-index: 11;
    }
    .step-label {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
