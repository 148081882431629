.chat-sidebar-container {
  height: calc(100vh - 110px);
  min-height: unset;

  .chat-topbar {
    height: 52px;
  }

  .chat-content-wrap {
    .chat-content {
      position: relative;
      height: calc(100vh - 245px);

      .message {
        position: relative;
        background: $gray-100;
        border-radius: 10px;
        padding: 1rem;

        &:before {
          position: absolute;
          content: '';
          top: 14px;
          right: -5px;
          background: transparent;
          border-style: solid;
          border-width: 0 10px 10px 0px;
          border-color: transparent transparent $gray-100 transparent;
          transform: rotate(-135deg);
        }
      }

      .user {
        .message {
          &:before {
            left: -5px;
            right: auto;
            transform: rotate(45deg);
          }
        }
      }

      .chat-input-area {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .spinner-glow {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% - 10px);
    }

    .reply-message {
      position: absolute;
      bottom: 50px;
      width: calc(100% - 26px);
      padding: 20px;
      border-radius: 12px;
      background: #e5e5e5;
    }

    .reply-message-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .chat-sidebar-wrap {
    width: 420px;

    .chat-menu-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .contacts-scrollable {
      position: relative;
      height: calc(100vh - 400px);

      .contact {
        position: relative;
        cursor: pointer;
        transition: 0.15s all ease-in;

        &:hover {
          background: $gray-100;
        }

        &:before {
          position: absolute;
          content: '';
          width: 8px;
          height: 8px;
          top: calc(50% - 4px);
          right: 30px;
          background: $light;
          border-radius: 50%;
        }

        &.online {
          &:before {
            background: $success;
          }
        }
      }
    }
  }

  .chat-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .chat-user-list {
    margin-top: 30px;
    height: 300px;
  }
}

.chat-icon {
  width: 24px;
  height: 24px;
}

.chat-edit-button {
  height: 30px;
  width: 70px;
  border-radius: 20px;
}

.user-chat-list {
  height: 65vh;
  overflow: auto;
}
