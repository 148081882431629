.attendance-wrap {
  .buttons-navigation {
    gap: 40px;
    margin-bottom: 40px;
    .btn-navigation {
      min-height: 80px;
      width: 240px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      color: $asure-neutral-70;
      cursor: pointer;
      &.active {
        background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
        color: $asure-secondary-100;
      } 
    }
  }

  .react-bootstrap-table {
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 8px;
      visibility: hidden;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(221, 217, 217);
      outline: none;
      border-radius: 15px;
      visibility: hidden;
    }
  }

  .react-bootstrap-table table {
    table-layout: auto;
  }

  .attendance-log {
    &-detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .validity-status {
    &.valid {
      color: $asure-success-100;
    }
    &.invalid {
      color: $asure-error-100;
    }
    &.pending {
      color: $asure-tertiary-100;
    }
  }

  .paid-status {
    &.paid {
      color: $asure-success-100;
    }
    &.unpaid {
      color: $asure-error-100;
    }
  }

  .employee-status {
    &.active {
      color: $asure-success-100;
    }
    &.resigned {
      color: $asure-error-100;
    }
  }

  .select-year {
    position: relative;
    overflow: visible;
    z-index: 10;
  }

  .checkin-upload {
    .button-upload-file {
      label {
        width: 179px;
      }
      div {
        width: 84% !important;
        img.btn-icon-only {
          margin-right: 0 !important;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
.attendance-log-detail {
  .attachment {
    color: #00cdc2;
  }
}

@media (min-width: 1024px) {
}

@media (max-width: 767px) {
  .attendance-wrap {
    .buttons-navigation {
      flex-direction: column;
      .btn-navigation {
        width: 100%;
        min-height: auto;
      }
    }
  }
}
