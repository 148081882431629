.date-range-picker-wrapper {
  position: relative;
  z-index: 999;

  input {
    width: 235px;
  }

  .date-range {
    position: absolute;
    z-index: 99;
    left: -40px;
    top: 50px;

    background-color: #fff;

    .date-range-confirm-btn {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }

    .rdrCalendarWrapper {
      padding-bottom: 50px;
      box-shadow: 0px 1px 2px #b8b8bb;
      border-radius: 4px;

      .rdrMonthAndYearWrapper {
        position: absolute;
        width: 100%;
        top: -10px;
      }

      .rdrDateDisplayWrapper,
      .rdrYearPicker,
      .rdrMonthPicker {
        display: none;
      }

      .rdrMonthName {
        text-align: center;
        height: 50px;
        padding-top: 15px;
      }

      .rdrMonth {
        width: 264px;
        padding: 0;

        .rdrWeekDays {
          border: 1px solid #5856d6;
        }

        .rdrDays {
          padding: 0 12px;
        }
      }

      .rdrDay {
        height: 42px;
      }

      .rdrDayStartOfWeek .rdrInRange {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      .rdrDayEndOfWeek .rdrInRange {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }

      .rdrStartEdge {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        left: 2px;
      }

      .rdrEndEdge {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        right: 2px;
      }

      .rdrInRange,
      .rdrStartEdge,
      .rdrEndEdge {
        color: #ffcccc !important;
      }

      .rdrStartEdge,
      .rdrEndEdge {
        &:after {
          content: '';
          width: 32px;
          height: 32px;
          display: block;
          position: absolute;
          background: #5856d6;
          border-radius: 100%;
        }
      }

      .rdrDayToday .rdrDayNumber span:after {
        content: '';
        width: 30px;
        height: 30px;
        border: 1px solid #1a1a1a;
        display: block;
        position: absolute;
        border-radius: 100%;
        background: transparent;
        top: 1px;
      }

      .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span {
        color: #fff;
      }

      .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
        color: #fff;
      }

      .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
        color: #1a1a1a;
      }
    }
  }

  .date-range-picker {
    background: #fff;
    border: 1px solid #00cdc2;
    border-radius: 20px;
    min-width: 240px;
    width: 240px;
    padding: 15px 20px 15px 20px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .date-range-picker-wrapper {
    .date-range {
      width: 100% !important;
      left: 0 !important;
    }

    .date-range-picker {
      min-width: 270px;
      width: 270px;
    }
  }
}
